@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.component-search-input > div {
  margin: 0 25vw 0 25vw;
  padding-bottom: 25px;
}

.component-search-input input {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  font-size: 16px ;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  padding: 10px 8px;
  width: 100%;
}

Input:focus{
  border-color: #205685;
}

Input{
  outline: none;
}

@media screen and (max-width: 767px) {
  .component-search-input > div {
    margin: 0 45px;
    padding-bottom: 25px;
  }
}

.component-phone-data { /*cadre de tous les éléments*/
  margin: 0 10%;
  position: flex;
}

.results-subtitle { /*texte information*/
  text-align: center;
  position: flex;
  color:#444444;
  font-size: 16px;
  margin-bottom: 20px;
}

/* Container de l'image / pièces / remise / total */
.phone-data-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.component-phone-data-picture img{
  height: 400px;
}

/* Boite affichage pièces et boite affichage prix */
.phone-data-info-box {
  -webkit-align-items: center!important;
          align-items: center!important;
  display: -webkit-flex;
  display: flex;
  font-size: 17px;
  width: 450px;
}

/* Libellé d'une boite générique */
.info-box-label {
  padding: 10px;
}

/* Prix d'une boite générique */
.info-box-price {
  padding: 10px;
  margin-left: auto !important;
}

/* Boite affichage pièces */
.item-info-box {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  height: calc(20px + 2*1rem);
}

input[type=checkbox].item-checkbox {
  display: none;
}

.item-info-box:hover { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.item-checkbox:checked+.item-info-box { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

/* Container boites prix (remise et total) */
.component-phone-data-prices {
  margin-bottom: 10px;
}

/* Boite affichage prix (remise et total) */
.amount-info-box {
  margin-top: 10px;
  margin-bottom: -10px;
  background: #F8F8F9;
}

@media screen and (max-width: 767px) {
  .component-phone-data {
    width: 100%;
    margin: auto;
  }

  .component-phone-data-picture {
    display: none;
  }

  .phone-data-info-box {
    width: auto;
  }
}

/* Gestion du choix des couleurs de pièces */
input[type=radio] {
  display: none;
}

.colorOptions {
  display: -webkit-flex;
  display: flex;
}

.cardRadio+.cardColorBorder { /*cercle autour du bouton choix de couleur*/
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  position: absolute;
  z-index: 10;
}

.cardColor { /*position du cercle de couleur dans le choix de couleur*/
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3.5px;
  margin-left: 3.5px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.cardColorBorder:hover { /*souris sur le choix de la couleur*/
  cursor: pointer;
  border: 1px solid #686D74 ;
}

.cardRadio:checked+.cardColorBorder { /*sous bouton choix de couleur*/
  border: 1px solid #205685;
}

.component-recap-page { /*cadre de tous les éléments de la page RecapPage.js */
    width: auto;
    margin: 0 24.5%;
}

.component-recap-page-title {
  text-align: center;
  color: #205685;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.component-recap-bloc { /*Bloc gris comprenant l'image, le modèle et le prix */
  display: grid;
  grid-template-columns: 3fr  3fr 3fr;
  grid-column: 1 / 3;
  background-color: #F8F8F9;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 18px;
}

.component-recap-bloc-image {
  grid-column: 1;
}

.component-recap-bloc-image img {
  width: 70%;
  object-fit: contain;
}

.component-recap-bloc-model {
  grid-column: 2;
  align-self: center;
}

.component-recap-bloc-model-title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

.component-recap-bloc-prices{
  grid-column: 3;
  align-self: center;
  padding-left: 10px;
  border-left: 3px solid #D9D9D9;
}

.component-recap-bloc-totalAmount {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .component-recap-page {
    width : auto;
    margin: 0 2%;
  }

  .component-recap-bloc {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 1 / 2;
  }

  .component-recap-bloc-image {
    display: none;
  }

  .component-recap-bloc-model {
    grid-column:  1;
    padding: 10px;
  }

  .component-recap-bloc-prices {
    grid-column:  2;
    padding: 10px;
  }
}

.component-promo-code { /* Composant avec la case code de réduction + bouton validation */
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-bottom: 15px;
}

#validate-promo-code-button {
  height: 40px;
  min-width: 120px;
  padding: 0 10px;
  margin-top: 8px;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  #validate-promo-code-button {
    font-size: 15px;
  }
}

.end-message {
  margin: 0 24.5%;
  font-size: 26px;
  color: #205685;
  text-align: center;
  font-weight: bolder;
}

.end-message button {
  display: inline-block;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  background-color:#205685;
  height: 40px;
  line-height: 42px;
  padding: 0 15px;
  margin-top: 8px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all .5s;
  transition-timing-function: cubic-bezier(.2, 3, .4, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.end-message-button button {
  margin: 40px 5px 0px 5px;
}

.end-message button:hover{
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.end-message button:active{
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

.switch-days-button {
  width: 4%;
  float:left;
}

.appointment-grid {
  width: 90%;
  float:left;
  border: 2px solid #205685;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.appointment-element {
  font-size: 22px;
  margin: 0 10%
}

.appointment-element-slot {
  font-size: 20px;
  border: solid;
  margin: 20px;
}

.checkbox-appointment-element-slot+.appointment-element-slot {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.checkbox-appointment-element-slot:hover+.appointment-element-slot { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.checkbox-appointment-element-slot:checked+.appointment-element-slot { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .end-message {
    margin: 0px;
    font-size: 18px;
  }

  .end-message-button button {
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    font-size: 17px;
  }

  .appointment-grid {
    width: 73%;
    margin: 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .appointment-element {
    margin: 0px 8px;
    font-size: 16px;
  }

  #appointment-element-2{
    display: none;
  }

  .appointment-element-slot {
    padding-top: 12px;
    height: 30px;
    font-size: 14px;
    margin: 25px 0;
  }

  .switch-days-button {
    width: 10%;
    margin: 0 5px;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.results-title {
  width: 50%;
  margin: 40px auto;
  text-align: center;
  color: #205685;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
}

.component-grid-list { /* Composant générique liste format grille */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 20px !important;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.component-grid-element { /* Composant générique élément de la liste format grille */
  border: 1px solid #EAE7EA;
  border-radius: 5px;
  padding: 10px;
  width: calc(3.5rem + 5vw);
  height: calc(3.5rem + 5vw);
  min-width: 100px;
  min-height: 100px;
}

.component-grid-element-title {
  font-size: calc(0.8rem + 0.2vw);
  margin-top: -5%;
}

#component-phones-title-element { /*Titre de la partie choix du modèle de téléphone*/
  margin-top: -2%;
}

.component-results-element { /* Composant générique "results" de la liste format grille */
  cursor: pointer;
}

.component-results-element:hover { /* Couleur au passage de la souris sur élément générique "results" */
  background-color: #eee;
}

.component-grid-element img { /* Format de l'image de l'élément générique */
  width: 100%;
  object-fit: contain;
}

#component-categories-results-element img { /* Format de l'image des catégories */
  height: 55%;
  min-height: 50px;
  margin: 15% 0px 5% 0px;
}

#component-brands-results-element img { /* Format de l'image des marques */
  height: 65%;
  width:100%;
  margin: 10% 0px 2% 0px;
}

#component-phones-results-element img { /* Format de l'image des modèles */
  height: 100px;
  width: calc(5rem + 3vw);
}

.component-skeleton-element-img {
  height: 130px;
}

.component-skeleton-element-title {
  height: 20px;
}

.component-grid-element-title { /* Titre des éléments génériques */
  position: relative;
  display:block;
  bottom: -10px;
}

/* GESTION DES BOUTONS */

/* Bouton retour */
.return-button {
  left: 25em;
  margin: auto ;
  margin-bottom: 0px;
  margin-left: 27%;
  margin-top: 20px;
  background-color: Transparent;
  font-family: 'Roboto', Bold, sans-serif;
  font-weight: 700;
  color: #205685;
  text-decoration:underline;
  cursor: pointer;
  font-size: 1em;
  padding: 0.7rem;
  border: 0;
  transition: all 0.5s;
  border-radius: 10px;
  width: auto;
}

.return-button::before{
  content:"< ";
}

.return-button:hover {
  transition: all 0.5s;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.return-button:after {
  opacity: 1;
  transition: all 0.5s;
}

/* Bouton de valdiation */
.validate-button{
  display: inline-block;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  background-color:#205685;
  height: 45px;
  padding: 0 15px;
  margin-top: 15px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all .5s;
  transition-timing-function: cubic-bezier(.2, 3, .4, 1);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.validate-button:hover{
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.validate-button:active{
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

/* AFFICHAGE MEDIA */
@media screen and (max-width: 767px) {
  .results-title {
    width:100%;
    margin: 40px auto;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -10px;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-align-items: center;
            align-items: center; /* left and right */
    -webkit-justify-content: center;
            justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
    height: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
    margin-top: -5%;
    margin-bottom: 0%;
    height: 90px;
  }

  .component-skeleton-element-img {
    height: 70px;
  }

  .component-skeleton-element-title {
    height: 15px;
  }

  .return-button{
    margin-left: 5%;
  }

  .validate-button {
    font-size: 17px;
  }
}

