.component-search-input > div {
  margin: 0 25vw 0 25vw;
  padding-bottom: 25px;
}

.component-search-input input {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  font-size: 16px ;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  padding: 10px 8px;
  width: 100%;
}

Input:focus{
  border-color: #205685;
}

Input{
  outline: none;
}

@media screen and (max-width: 767px) {
  .component-search-input > div {
    margin: 0 45px;
    padding-bottom: 25px;
  }
}
