.end-message {
  margin: 0 24.5%;
  font-size: 26px;
  color: #205685;
  text-align: center;
  font-weight: bolder;
}

.end-message button {
  display: inline-block;
  box-shadow: none;
  appearance: none;
  border: 0;
  outline: 0;
  background-color:#205685;
  height: 40px;
  line-height: 42px;
  padding: 0 15px;
  margin-top: 8px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all .5s;
  transition-timing-function: cubic-bezier(.2, 3, .4, 1);
  user-select: none;
  box-sizing: border-box;
}

.end-message-button button {
  margin: 40px 5px 0px 5px;
}

.end-message button:hover{
    transform: scale(1.1, 1.1);
}

.end-message button:active{
    transform: scale(1.05, 1.05);
}

.switch-days-button {
  width: 4%;
  float:left;
}

.appointment-grid {
  width: 90%;
  float:left;
  border: 2px solid #205685;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.appointment-element {
  font-size: 22px;
  margin: 0 10%
}

.appointment-element-slot {
  font-size: 20px;
  border: solid;
  margin: 20px;
}

.checkbox-appointment-element-slot+.appointment-element-slot {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.checkbox-appointment-element-slot:hover+.appointment-element-slot { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.checkbox-appointment-element-slot:checked+.appointment-element-slot { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .end-message {
    margin: 0px;
    font-size: 18px;
  }

  .end-message-button button {
    height: 40px;
    line-height: 40px;
    margin-top: 20px;
    font-size: 17px;
  }

  .appointment-grid {
    width: 73%;
    margin: 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .appointment-element {
    margin: 0px 8px;
    font-size: 16px;
  }

  #appointment-element-2{
    display: none;
  }

  .appointment-element-slot {
    padding-top: 12px;
    height: 30px;
    font-size: 14px;
    margin: 25px 0;
  }

  .switch-days-button {
    width: 10%;
    margin: 0 5px;
  }
}
