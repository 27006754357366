@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.results-title {
  width: 50%;
  margin: 40px auto;
  text-align: center;
  color: #205685;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
}

.component-grid-list { /* Composant générique liste format grille */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 20px !important;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.component-grid-element { /* Composant générique élément de la liste format grille */
  border: 1px solid #EAE7EA;
  border-radius: 5px;
  padding: 10px;
  width: calc(3.5rem + 5vw);
  height: calc(3.5rem + 5vw);
  min-width: 100px;
  min-height: 100px;
}

.component-grid-element-title {
  font-size: calc(0.8rem + 0.2vw);
  margin-top: -5%;
}

#component-phones-title-element { /*Titre de la partie choix du modèle de téléphone*/
  margin-top: -2%;
}

.component-results-element { /* Composant générique "results" de la liste format grille */
  cursor: pointer;
}

.component-results-element:hover { /* Couleur au passage de la souris sur élément générique "results" */
  background-color: #eee;
}

.component-grid-element img { /* Format de l'image de l'élément générique */
  width: 100%;
  object-fit: contain;
}

#component-categories-results-element img { /* Format de l'image des catégories */
  height: 55%;
  min-height: 50px;
  margin: 15% 0px 5% 0px;
}

#component-brands-results-element img { /* Format de l'image des marques */
  height: 65%;
  width:100%;
  margin: 10% 0px 2% 0px;
}

#component-phones-results-element img { /* Format de l'image des modèles */
  height: 100px;
  width: calc(5rem + 3vw);
}

.component-skeleton-element-img {
  height: 130px;
}

.component-skeleton-element-title {
  height: 20px;
}

.component-grid-element-title { /* Titre des éléments génériques */
  position: relative;
  display:block;
  bottom: -10px;
}

/* GESTION DES BOUTONS */

/* Bouton retour */
.return-button {
  left: 25em;
  margin: auto ;
  margin-bottom: 0px;
  margin-left: 27%;
  margin-top: 20px;
  background-color: Transparent;
  font-family: 'Roboto', Bold, sans-serif;
  font-weight: 700;
  color: #205685;
  text-decoration:underline;
  cursor: pointer;
  font-size: 1em;
  padding: 0.7rem;
  border: 0;
  transition: all 0.5s;
  border-radius: 10px;
  width: auto;
}

.return-button::before{
  content:"< ";
}

.return-button:hover {
  transition: all 0.5s;
  transform: scale(1.2);
}

.return-button:after {
  opacity: 1;
  transition: all 0.5s;
}

/* Bouton de valdiation */
.validate-button{
  display: inline-block;
  box-shadow: none;
  appearance: none;
  border: 0;
  outline: 0;
  background-color:#205685;
  height: 45px;
  padding: 0 15px;
  margin-top: 15px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all .5s;
  transition-timing-function: cubic-bezier(.2, 3, .4, 1);
  user-select: none;
  box-sizing: border-box;
}

.validate-button:hover{
    transform: scale(1.1, 1.1);
}

.validate-button:active{
    transform: scale(1.05, 1.05);
}

/* AFFICHAGE MEDIA */
@media screen and (max-width: 767px) {
  .results-title {
    width:100%;
    margin: 40px auto;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -10px;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center; /* left and right */
    justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
    height: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
    margin-top: -5%;
    margin-bottom: 0%;
    height: 90px;
  }

  .component-skeleton-element-img {
    height: 70px;
  }

  .component-skeleton-element-title {
    height: 15px;
  }

  .return-button{
    margin-left: 5%;
  }

  .validate-button {
    font-size: 17px;
  }
}
