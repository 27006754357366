.component-phone-data { /*cadre de tous les éléments*/
  margin: 0 10%;
  position: flex;
}

.results-subtitle { /*texte information*/
  text-align: center;
  position: flex;
  color:#444444;
  font-size: 16px;
  margin-bottom: 20px;
}

/* Container de l'image / pièces / remise / total */
.phone-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.component-phone-data-picture img{
  height: 400px;
}

/* Boite affichage pièces et boite affichage prix */
.phone-data-info-box {
  align-items: center!important;
  display: flex;
  font-size: 17px;
  width: 450px;
}

/* Libellé d'une boite générique */
.info-box-label {
  padding: 10px;
}

/* Prix d'une boite générique */
.info-box-price {
  padding: 10px;
  margin-left: auto !important;
}

/* Boite affichage pièces */
.item-info-box {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  height: calc(20px + 2*1rem);
}

input[type=checkbox].item-checkbox {
  display: none;
}

.item-info-box:hover { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.item-checkbox:checked+.item-info-box { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

/* Container boites prix (remise et total) */
.component-phone-data-prices {
  margin-bottom: 10px;
}

/* Boite affichage prix (remise et total) */
.amount-info-box {
  margin-top: 10px;
  margin-bottom: -10px;
  background: #F8F8F9;
}

@media screen and (max-width: 767px) {
  .component-phone-data {
    width: 100%;
    margin: auto;
  }

  .component-phone-data-picture {
    display: none;
  }

  .phone-data-info-box {
    width: auto;
  }
}

/* Gestion du choix des couleurs de pièces */
input[type=radio] {
  display: none;
}

.colorOptions {
  display: flex;
}

.cardRadio+.cardColorBorder { /*cercle autour du bouton choix de couleur*/
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  position: absolute;
  z-index: 10;
}

.cardColor { /*position du cercle de couleur dans le choix de couleur*/
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3.5px;
  margin-left: 3.5px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.cardColorBorder:hover { /*souris sur le choix de la couleur*/
  cursor: pointer;
  border: 1px solid #686D74 ;
}

.cardRadio:checked+.cardColorBorder { /*sous bouton choix de couleur*/
  border: 1px solid #205685;
}
