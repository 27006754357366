.component-recap-page { /*cadre de tous les éléments de la page RecapPage.js */
    width: auto;
    margin: 0 24.5%;
}

.component-recap-page-title {
  text-align: center;
  color: #205685;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.component-recap-bloc { /*Bloc gris comprenant l'image, le modèle et le prix */
  display: grid;
  grid-template-columns: 3fr  3fr 3fr;
  grid-column: 1 / 3;
  background-color: #F8F8F9;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 18px;
}

.component-recap-bloc-image {
  grid-column: 1;
}

.component-recap-bloc-image img {
  width: 70%;
  object-fit: contain;
}

.component-recap-bloc-model {
  grid-column: 2;
  align-self: center;
}

.component-recap-bloc-model-title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

.component-recap-bloc-prices{
  grid-column: 3;
  align-self: center;
  padding-left: 10px;
  border-left: 3px solid #D9D9D9;
}

.component-recap-bloc-totalAmount {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .component-recap-page {
    width : auto;
    margin: 0 2%;
  }

  .component-recap-bloc {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 1 / 2;
  }

  .component-recap-bloc-image {
    display: none;
  }

  .component-recap-bloc-model {
    grid-column:  1;
    padding: 10px;
  }

  .component-recap-bloc-prices {
    grid-column:  2;
    padding: 10px;
  }
}

.component-promo-code { /* Composant avec la case code de réduction + bouton validation */
  display: inline-flex;
  padding-bottom: 15px;
}

#validate-promo-code-button {
  height: 40px;
  min-width: 120px;
  padding: 0 10px;
  margin-top: 8px;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  #validate-promo-code-button {
    font-size: 15px;
  }
}
